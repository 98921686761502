import React, { useEffect, useState } from "react";
import Navbar from "../components/Layouts/Navbar";
import Footer from "../components/Layouts/Footer";
import Breadcrumb from '../components/Layouts/Breadcrumb';
import AuthLogin from '../components/Auth/AuthLogin';
import axios from "axios";
import Head from "next/head";
import { getSession } from "../lib/session";

const Login = ({ metaDetails, slug }) => {
    const [metaData, setMetaData] = useState(null);
    const [metaSlug, setmetaSlug] = useState(null);

    useEffect(() => {
        try {
            const slug = (window.location.pathname).split('/').pop();
            const complete_url = window.location.href;
            setmetaSlug(complete_url)
            axios.get(process.env.API_URL + '/meta/' + slug).then((res) => {
                if (res.data?.code == 200) {
                    setMetaData(res.data)
                } 
            }).catch((error) => {

            })
        } catch (err) {

        }
    }, [])
    useEffect(() => {
        // window.location.replace('/api/auth/login')
        // signIn()
    }, [])
    return (
        <>
            <Head>
                <title>{(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "}</title>
                <meta name="description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
                <meta name="keywords" content={(metaDetails != undefined) ? metaDetails.meta_keywords : (metaData) ? metaData?.meta_keywords : "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends."} />
                <meta property="og:title" content={(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "} />
                <meta property="og:description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
                <meta name="twitter:title" content={(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "} />
                <meta name="twitter:description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
                <meta property="og:url" content={(slug) ? slug : metaSlug} />
            </Head>
            <Navbar />
            <div className=' mt-10 flex flex-col justify-center  lg:mt-12 lg:mb-4 my-4'>
            
            <AuthLogin/>
            </div>
            <Footer />
        </>
    );
}

export async function getServerSideProps({ req, res, resolvedUrl }) {

    let session=getSession(req)
    if(session){
        return {
            redirect: {
              destination: '/dashboard',
              permanent: false
            },
          };
    }
    let slug = resolvedUrl;
    if (slug.includes('/_next/')) {
        // checking if it making request json file then skip to fetch Meta Data
        return {
            props: {
                metaDetails: {
                    meta_title: "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
                    meta_description: "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
                    meta_keywords: "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends."
                },
                slug: slug
            }
        };
    } else {
        // Remove the trailing slash, if any
        const cleanedSlug = slug.replace(/\/$/, '');
        try {
            const response = await axios.get(process.env.API_URL + '/meta/' + cleanedSlug);
            const metaDetails = response.data;

            if (metaDetails == undefined || metaDetails == '' || metaDetails == null || metaDetails?.code === 404) {
                return {
                    props: {
                        metaDetails: {
                            meta_title: "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
                            meta_description: "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
                            meta_keywords: "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends."
                        },
                        slug: slug
                    }
                }
            } else {
                return {
                    props: {
                        metaDetails: metaDetails,
                        slug: slug
                    },
                };

            }
        } catch (error) {
            // console.error('Error fetching API data:', error);

            return {
                props: {
                    metaDetails: {
                        meta_title: "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
                        meta_description: "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
                        meta_keywords: "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends."
                    },
                    slug: slug
                },
            };
        }
    }
}


export default Login
